import React from "react";
import { AdminApp } from './Admin'

const Portals = () => {
    return (
        <>
            <AdminApp />
        </>
    )
  
}
 
export default Portals