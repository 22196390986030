import React from "react";

const Returns = () => {
  return (
    <div>
      <h2 className="text-2xl font-medium text-center mt-20">
        Work in progress contact for return 9583195831
      </h2>
    </div>
  );
};

export default Returns;
