
module.exports = {
  picserver: 'https://www.wholesaller.com/api/',
  servername: 'https://www.wholesaller.com/api',
};

// module.exports = {
//     picserver: 'http://localhost:5000/',
//     servername: 'http://localhost:5000'
// }
