import React from 'react';

// reactstrap components
// import { Row, Container, Navbar, NavItem, Button, NavLink } from 'reactstrap';

function DemoFooter() {
  return (
    <>
      {/* <footer className='footer footer-black footer-white'>
        <Container>
          <div class='ui divider'></div>
          <Row>
            <nav className='footer-nav'>
              <span className='copyright'>
                © {new Date().getFullYear()} Wholesaller
              </span>
            </nav>

            <div className='credits ml-auto'>
              <Button
                className='btn-just-icon '
                color='link'
                href='#pablo'
                onClick={(e) => e.preventDefault()}
              >
                <i className='fa fa-twitter' />
              </Button>

              <Button
                className='btn-just-icon btn ml-1'
                color='link'
                href='#pablo'
                onClick={(e) => e.preventDefault()}
              >
                <i className='fa fa-facebook' />
              </Button>

              <Button
                className='btn-just-icon btn ml-1'
                color='link'
                href='#pablo'
                onClick={(e) => e.preventDefault()}
              >
                <i className='fa fa-linkedin' />
              </Button>

              <Button
                className='btn-just-icon btn ml-1'
                color='link'
                href='#pablo'
                onClick={(e) => e.preventDefault()}
              >
                <i className='fa fa-instagram' />
              </Button>

              <Button
                className='btn-just-icon btn ml-1'
                color='link'
                href='#pablo'
                onClick={(e) => e.preventDefault()}
              >
                <i className='fa fa-pinterest' />
              </Button>
         
            </div>
          </Row>
        </Container>
      </footer> */}
    </>
  );
}

export default DemoFooter;
